import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "Bachelor's of Science Degree",
    years: "2015-2017, 2018-2020",
    content:
      "Graduated from the University of Virginia specialized in Computer Science, Systems Engineering, and UX Design.",
  },
  {
    id: 2,
    title: "IB Program at Warwick High School",
    years: "2011-2015",
    content:
      "Salutatorian.",
  }
];

const experienceData = [
  {
    id: 1,
    title: "Freelance Developer",
    years: "2019 - Present",
    content:
      "Freelancing as a web developer building high-fidelity ReactJS web sites.",
  },
  {
    id: 2,
    title: "Junior Developer",
    years: "2017 - 2018",
    content:
      "Junior Developer at financial tech startup ChartIQ, now Cosaic. Full-time. Worked full-stack with ReactJS and NodeJS.",
  },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row">
          <div className="col-md-6">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
